<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <v-row>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.management.manager") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <employee-profile v-else :employee="employee.management.manager" />
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.management.leave_managers") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <employee-profile
            v-for="item in employee.leaveSettings.leaveManagers"
            :employee="item.manager"
            :key="item.rank"
          />
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.management.shift_managers") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <employee-profile
            v-for="item in employee.timeSettings.shiftManagers"
            :employee="item.manager"
            :key="item.rank"
          />
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.management.overtime_managers") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <employee-profile
            v-for="item in employee.timeSettings.overtimeManagers"
            :employee="item.manager"
            :key="item.rank"
          />
        </v-col>
        <v-col cols="12" class="tertiary--text subtitle-1 font-weight-bold pb-0">
          {{ $t("hr.employee.management.expense_managers") }}
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-progress-linear v-if="loading" color="grey lighten-3" indeterminate />
          <employee-profile
            v-for="item in employee.expenseSettings.expenseManagers"
            :employee="item.manager"
            :key="item.rank"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { MANAGERS } from "../query";

  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description index Component Logic
   * @date 27.07.2020
   */
  export default {
    name: "managers",
    data: () => ({
      loading: true,
      employee: {
        management: [],
        timeSettings: {
          shiftManagers: [],
          overtimeManagers: []
        },
        leaveSettings: {
          leaveManagers: []
        },
        expenseSettings: {
          expenseManagers: []
        }
      }
    }),
    methods: {
      async fetchItems() {
        this.loading = true;
        await this.$apollo
          .query({
            query: MANAGERS,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchItems();
    }
  };
</script>

<style scoped></style>
